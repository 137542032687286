import { DATA_SET } from 'constants/data-sources';
import { CONFIG as panelConfig } from '../../PanelSettings/Panel';
import { CONFIG as timeConfig } from '../../PanelSettings/TimeRangeOptions';
import {
  COL_OPTIONS,
  getColumnId,
  pieValuesReducer,
  CONFIG as valueOptionsConfig,
  VALUES_OPTIONS,
} from '../../PanelSettings/pie-chart/ValueOptions';
import {
  DISPLAY_OPTIONS,
  CONFIG as displayConfig,
} from '../../PanelSettings/pie-chart/Display';
import {
  LEGEND_MODE,
  LEGEND_PLACEMENT,
  LEGEND_VALUES,
  CONFIG as legendConfig,
} from '../../PanelSettings/pie-chart/Legend';
import { CONFIG as formatterConfig } from '../../PanelSettings/Formatter';
import { QUERY_TYPE } from 'modules/alarms/utils';
import { useFormikContext } from 'formik';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import DataTable from 'components/DataTable';
import partition from 'lodash/partition';
import isNil from 'lodash/isNil';
import { transformFramesToTable } from '../timeseries';
import moment from 'moment';
import {
  darkModeColors,
  format,
  formatResultToString,
  lightModeColors,
  noDataMessage,
} from '../utils';

const ID = 'pie_chart';
export const CONFIG = {
  id: ID,
  settings: {
    general: [
      panelConfig,
      { ...valueOptionsConfig, props: { name: ID } },
      { ...displayConfig, props: { name: ID } },
      { ...legendConfig, props: { name: ID } },
      { ...formatterConfig, props: { name: `${ID}.${formatterConfig.id}` } },
      { ...timeConfig, props: { name: ID } },
    ],
    overrides: null,
  },
  name: 'Pie Chart',
  description: 'Pie Chart',
  multiNodes: true,
  defaultNodeConfig: {
    query_type: QUERY_TYPE.AGG,
    dataset: DATA_SET.METRICS,
    limit: null,
    page: null,
    responseType: 'series',
  },
  defaultConfig: {
    ...valueOptionsConfig.default,
    ...displayConfig.default,
    ...legendConfig.default,
    [formatterConfig.id]: {},
  },
  showTableView: true,
  configurableSource: true,
};

const PieChart = ({ data, width, height, config }) => {
  const [selected, setSelected] = useState(null);
  const formik = useFormikContext();
  const theme = useTheme();

  const values = config || formik?.values;
  const { type: chartType, labels: chartLabels } = values[ID][displayConfig.id];
  const valuesOptions = values[ID][valueOptionsConfig.id];
  const {
    mode: legendMode,
    values: legendValues,
    placement,
    show: showLegend,
  } = values[ID][legendConfig.id];
  
  const valueFormmater = values[ID][formatterConfig.id];
  const legendAtBottom = placement === LEGEND_PLACEMENT.bottom.value;
  const legendHeight = legendAtBottom ? 100 : undefined;

  const pieHeight = height - (legendHeight || 0);
  const pieWidth = legendAtBottom ? width : (66 * width) / 100;
  const radius = Math.min(pieWidth, pieHeight - 30) / 2;
  const innerRadius =
    chartType === DISPLAY_OPTIONS.donut.value ? radius / 2 : 0;

  const chartData = useMemo(() => {
    const rows = [];
    if (!data?.length) {
      return [];
    }

    const hasMultipleNodes = values.node_configs.length > 1;

    const [seriesFrames, numberFrames] = partition(
      data,
      (o) => o.type === 'series',
    );

    let dataMap = {};
    const { graphData: table } = transformFramesToTable(seriesFrames);
    if (table.length) {
      dataMap = {
        timestamp: {
          values: table[0], // first row is timestamp
          numeric: false,
        },
      };
      (data || []).forEach((row, idx) => {
        const id = getColumnId(row, hasMultipleNodes);
        dataMap[id] = {
          values: table[idx + 1],
          numeric: true,
        };
      });
    }

    if (numberFrames?.length) {
      numberFrames.forEach((row) => {
        const id = getColumnId(row, hasMultipleNodes);
        dataMap[id] = {
          values: row.values,
          numeric: true,
        };
      });
    }

    let colsToDisplay = [];
    if (valuesOptions.columns.some((col) => col === COL_OPTIONS.all.value)) {
      colsToDisplay = Object.keys(dataMap);
    } else if (
      valuesOptions.columns.some((col) => col === COL_OPTIONS.numeric.value)
    ) {
      colsToDisplay = Object.keys(dataMap).filter(
        (col) => dataMap[col].numeric,
      );
    } else {
      colsToDisplay = valuesOptions.columns;
    }

    if (valuesOptions.type === VALUES_OPTIONS.reduce.value) {
      colsToDisplay.forEach((name) => {
        if (!dataMap[name]) {
          return;
        }
        const { numeric, values } = dataMap[name];
        const vals = numeric ? values : values.map((v) => moment(v).toJSON());
        rows.push({
          name,
          value: pieValuesReducer(vals, valuesOptions.reduce, numeric),
          numeric,
        });
      });
    }

    if (valuesOptions.type === VALUES_OPTIONS.all_values.value) {
      colsToDisplay.forEach((name) => {
        if (!dataMap[name]) {
          return;
        }
        const { values, numeric } = dataMap[name];
        values.slice(0, valuesOptions.limit).forEach((value, idx) => {
          rows.push({
            name: `${
              !numeric
                ? ''
                : moment(dataMap['timestamp'].values[idx]).toJSON() + ' '
            }${name}`,
            value: numeric ? value : moment(value).toJSON(),
            numeric,
          });
        });
      });
    }

    const total = rows.reduce((sum, row) => {
      return sum + (row.numeric ? row.value || 0 : 0);
    }, 0);

    const chartData = rows
      .filter((row) => !isNil(row.value))
      .map((row, idx) => {
        const percent =
          row.numeric && !isNaN(row.value)
            ? parseInt((row.value * 100) / total)
            : null;

        return {
          ...row,
          percent,
          formattedPercent: isNil(percent) ? '-' : `${percent}%`,
          color:
            theme.palette.mode === 'dark'
              ? darkModeColors[idx]
              : lightModeColors[idx],
          formattedValue: formatResultToString(
            format({ value: row.value, ...valueFormmater }),
          ),
        };
      });

    return chartData;
  }, [
    data,
    valuesOptions.type,
    valuesOptions.reduce,
    valuesOptions.columns,
    valuesOptions.limit,
    valueFormmater,
    theme.palette.mode,
  ]);

  const chart = useMemo(() => {
    if (!chartData?.length) {
      return (
        <Box height={height} width={'100%'}>
          {noDataMessage}
        </Box>
      );
    }

    return (
      <Grid height={height} container padding={1} columnSpacing={1}>
        <Grid
          item
          container
          xs={!showLegend || legendAtBottom ? 12 : 8}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Tooltip
            title={selected && <Label item={selected} showValue />}
            followCursor
          >
            <Box height={pieHeight}>
              <svg width={pieWidth} height={pieHeight}>
                <Group top={pieHeight / 2} left={pieWidth / 2}>
                  <Pie
                    data={chartData}
                    pieValue={(d) => d.value}
                    outerRadius={radius}
                    innerRadius={innerRadius}
                    cornerRadius={0}
                    padAngle={0.005}
                  >
                    {(pie) => {
                      const paths = pie.arcs.map((arc, index) => {
                        const { color, name, formattedValue } = arc.data;
                        const isSelected =
                          selected !== null && selected.name === name;

                        return (
                          <g
                            key={`arc-${name}-${index}`}
                            onMouseEnter={() => {
                              setSelected({
                                name,
                                color,
                                formattedValue,
                              });
                            }}
                            onMouseLeave={(e) => {
                              setSelected(null);
                            }}
                          >
                            <path
                              d={pie.path(arc)}
                              fill={color}
                              stroke={theme.palette.background.primary}
                              strokeWidth={1}
                              style={{
                                opacity:
                                  selected === null ? 1 : isSelected ? 1 : 0.75,
                                transform: isSelected
                                  ? 'scale(1.02)'
                                  : 'scale(1)',
                                transition: 'transform 0.1s ease-in-out',
                              }}
                            />
                          </g>
                        );
                      });

                      const labels = pie.arcs.map((arc) => {
                        const [centroidX, centroidY] = pie.path.centroid(arc);

                        return (
                          arc.data.percent > 3 && (
                            <text
                              x={centroidX}
                              y={centroidY}
                              dy=".33em"
                              fontSize={10}
                              textAnchor="middle"
                              fill="#fff"
                              pointerEvents={'none'}
                              className="pie-chart-label"
                            >
                              {chartLabels
                                .map((opt) => {
                                  switch (opt) {
                                    case 'percent':
                                      return arc.data[
                                        LEGEND_VALUES.formattedPercent.value
                                      ];
                                    case 'value':
                                      return arc.data[
                                        LEGEND_VALUES.formattedValue.value
                                      ];
                                    default:
                                      return arc.data[opt];
                                  }
                                })
                                .join(', ')}
                            </text>
                          )
                        );
                      });

                      return (
                        <>
                          {paths}
                          {labels}
                        </>
                      );
                    }}
                  </Pie>
                </Group>
              </svg>
            </Box>
          </Tooltip>
        </Grid>
        {showLegend && (
          <Grid item xs={legendAtBottom ? 12 : 4}>
            <Legend
              data={chartData}
              height={legendHeight || height}
              mode={legendMode}
              values={legendValues}
              legendAtBottom={legendAtBottom}
            />
          </Grid>
        )}
      </Grid>
    );
  }, [
    chartData,
    selected,
    height,
    width,
    legendMode,
    legendValues,
    placement,
    showLegend,
    chartType,
    chartLabels,
    valueFormmater,
  ]);

  return chart;
};

const Legend = ({ data, height, legendAtBottom, mode, values }) => {
  const displayAsList = mode === LEGEND_MODE.list.value;
  const displayAsTable = mode === LEGEND_MODE.table.value;

  const columns = displayAsTable && [
    {
      dataField: 'name',
      formatter: (_, row) => <Label item={row} />,
    },
  ];

  displayAsTable &&
    values.forEach((type) => {
      columns.push({ dataField: type, text: LEGEND_VALUES[type].label });
    });

  const list =
    displayAsList &&
    data.map((row) => ({
      name: `${row.name} ${values
        .map((type) => `: ${row[type]}`)
        .join(', ')}`,
      color: row.color,
    }));

  if (!data.length) {
    return null;
  }
  

  return (
    <>
      {displayAsList && (
        <Box maxHeight={height} overflow="auto" p={1}>
          {legendAtBottom
            ? list.map((item) => (
                <Box display={'inline-block'}>
                  <Label item={item} />
                </Box>
              ))
            : list.map((item) => <Label item={item} />)}
        </Box>
      )}
      {displayAsTable && (
        <DataTable
          tableContainerStyle={{ maxHeight: height }}
          size="small"
          keyField="name"
          columns={columns}
          data={data}
          stickyHeader
        />
      )}
    </>
  );
};

const Label = ({ item, showValue }) => {
  if (!item) {
    return null;
  }
  return (
    <Stack direction="row" alignItems="center" spacing={0.75} mx={1.5}>
      <Box
        width={10}
        height={10}
        sx={{ backgroundColor: item.color, aspectRatio: '1/1' }}
      />
      <Typography variant="body2">
        {item.name} {showValue ? item.formattedValue : ''}
      </Typography>
    </Stack>
  );
};

export default PieChart;
