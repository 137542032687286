import React, { useState, useMemo, useEffect, useRef } from 'react';
import moment from 'moment';
import randomcolor from 'randomcolor';
import UplotReact from 'uplot-react';
import useMeasure from 'react-use-measure';
import Box from '@mui/material/Box';
import Tooltip from './Tooltip';
import Legend from './Legend';
import { mergeHooks, defaultColors } from './utils';
import WidthProvider from 'components/WidthProvider';
import Threshold from './Threshold';

const Chart = ({
  data,
  tooltipData,
  height,
  labels,
  colors = defaultColors,
  axes,
  chartSeries,
  cursor,
  appendPlugins,
  padding,
  scales,
  bands,
  tooltipMaxWidth,
  tzDate,
  focusProx = 30,
  appendHooks = [],
  showLegend = true,
  tooltip: {
    showTooltip = true,
    multiSeriesTooltip = true,
    highlightFocusedSeries = false,
  } = {
    showTooltip: true,
    multiSeriesTooltip: true,
    highlightFocusedSeries: false,
  },
  legendAppendValues,
  threshold,
  legendView = 'list',
  children,
  yValFormatter = (value) => value,
  yRightValFormatter = (value) => value,
  xTooltipFormatter = (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
}) => {
  const [legendRef, legendBounds] = useMeasure();
  const [width, setWidth] = useState();
  const plotRef = useRef(null);
  const plotInstanceRef = useRef(null);

  // State to control tooltip locking. When true, the tooltip stays fixed at its current position
  const [tooltipLocked, setTooltipLocked] = useState(false);

  const [bbox, setBbox] = useState({
    left: 0,
    top: 0,
  });

  const [hooks, setHooks] = useState(
    mergeHooks(
      {
        syncRect: [
          (_, rect) => {
            setBbox(rect);
          },
        ],
      },
      appendHooks,
    ),
  );

  const addHooks = (hooksInput, replace) => {
    setHooks((prevHooks) => mergeHooks(prevHooks, hooksInput, replace));
  };

  const series = useMemo(() => {
    if (!labels || !labels.length) {
      return undefined;
    }
    const series = [{}];
    if (labels && data) {
      labels.forEach((label, index) => {
        let color;

        if (colors[index]) {
          color = colors[index];
        } else {
          color = randomcolor({ luminosity: 'bright', seed: label + index });
        }

        series.push({
          points: { show: data[0].length === 1 },
          stroke: color,
          name: label,
        });
      });
    }

    return series;
  }, [labels?.length, colors?.length]);

  useEffect(() => {
    if (showLegend && (!width || !legendBounds.height)) {
      return;
    }
    plotInstanceRef?.current?.setSize({
      width: width,
      height: parseInt(height - legendBounds.height),
    });
  }, [plotInstanceRef.current, width, height, legendBounds?.height]);

  // useEffect(() => {
  //   plotInstanceRef?.current?.setData(data);
  // }, [data.length, data[0].length]);


  const chart = useMemo(() => {
    const options = {
      axes,
      width: parseInt(width),
      height: parseInt(height - legendBounds.height),
      series: chartSeries || series,
      scales,
      bands,
      legend: {
        show: false,
      },
      tzDate,
      padding,
      drag: { setScale: false },
      focus: {
        alpha: 1,
      },
      ms: 1,
      cursor: cursor || {
        focus: {
          prox: focusProx,
        },
      },
      hooks,
      plugins: appendPlugins,
      tooltipData,
    };

    return (
      <>
        <Box
          minHeight={showLegend ? '70%' : '100%'}
          onClick={() => {
            // Toggle the tooltip lock state
            setTooltipLocked(!tooltipLocked);
          }}
        >
          {showLegend && legendBounds.height !== 0 && width !== 0 && (
            <Box ref={plotRef}></Box>
          )}
          {!showLegend && width !== 0 && <Box ref={plotRef}></Box>}
          <UplotReact
            options={options}
            data={data}
            target={plotRef.current}
            onCreate={(u) => {
              plotInstanceRef.current = u;
            }}
          />
        </Box>
        {showTooltip && (
          <Tooltip
            addHooks={addHooks}
            yValFormatter={yValFormatter}
            yRightValFormatter={yRightValFormatter}
            xTooltipFormatter={xTooltipFormatter}
            multiSeriesTooltip={multiSeriesTooltip}
            tooltipMaxWidth={tooltipMaxWidth}
            highlightFocusedSeries={highlightFocusedSeries}
            xIsTime={scales.x?.time}
            bbox={bbox}
            tooltipLocked={tooltipLocked}
            setTooltipLocked={setTooltipLocked}
          />
        )}
        {threshold && (
          <Threshold
            addHooks={addHooks}
            thresholds={threshold.thresholds}
            lineType={threshold.lineType}
            mode={threshold.thresholdMode}
          />
        )}
      </>
    );
  }, [
    hooks,
    chartSeries,
    axes[0].values,
    axes[1].values,
    axes[2]?.values,
    showTooltip,
    multiSeriesTooltip,
    bbox.left,
    bbox.top,
    threshold,
    width,
    showLegend,
    legendBounds.height,
    tooltipLocked,
  ]);

  const legend = useMemo(() => {
    if (showLegend && legendRef) {
      return (
        <Legend
          series={chartSeries || series}
          data={data}
          legendRef={legendRef}
          legendView={legendView}
          legendAppendValues={legendAppendValues}
          yValFormatter={yValFormatter}
          yRightValFormatter={yRightValFormatter}
        />
      );
    }
  }, [
    legendRef.current,
    showLegend,
    legendView,
    legendAppendValues?.length,
    yValFormatter,
    yRightValFormatter,
  ]);

  return (
    <WidthProvider>
      {(width) => {
        setWidth(width);
        return (
          <Box width={width}>
            <Box
              sx={{
                position: 'relative',
                width,
                height: height,
              }}
            >
              {chart}
              {showLegend && legend}
              {React.Children.map(children, (child) =>
                React.cloneElement(child, {
                  addHooks,
                }),
              )}
            </Box>
          </Box>
        );
      }}
    </WidthProvider>
  );
};

export default Chart;
