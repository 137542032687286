import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  tooltipContainer: ({ bbox }) => ({
    position: 'fixed',
    left: bbox.left,
    top: bbox.top,
    pointerEvents: 'none',
    zIndex: theme.zIndex.tooltip,
  }),
  tooltip: ({ x, y, maxWidth }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    transform: `translate(${x}px, ${y}px)`,
    transition: 'transform ease-out 0.1s',
    left: 0,
    top: 0,
    width: 'max-content',
    maxWidth,
    wordBreak: 'break-word',
    padding: '0.5rem',
    maxHeight: '300px',
    overflowY: 'auto',
    pointerEvents: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0,0,0,0.2)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0,0,0,0.3)',
    },
    boxShadow: `${theme.palette.grey[900]} 0rem 0.75rem 2rem 0rem`,
    borderRadius: '0.5rem',
  }),
}));
