import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import React, { useState, useMemo, useRef, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Form, Field } from 'formik';
import Select from 'components/Form/Formik/FormikReactSelect';
import TextField from 'components/Form/Formik/FormikInput';
import QueryVariable, { OTEL_KEY, OTEL_VALUES } from './QueryVariable';
import ConstantVariable from './ConstantVariable';
import Button from 'components/Button';
import Box from '@mui/material/Box';
import { useSelector } from 'store';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Switch from 'components/Form/Switch';

const variableTypes = [
  { label: 'Query', value: 'query' },
  { label: 'Constant', value: 'constant' },
];

const validationSchema = (variableOptions) =>
  yup.object({
    name: yup
      .string()
      .required('Name is required')
      .matches(/^[a-zA-Z0-9]*$/, 'Name must be alphanumeric without spaces')
      .max(50, 'Name must be less than 50 characters')
      .test(
        'uniquename',
        'A variable with the same name already exists',
        function (val) {
          return !variableOptions.find((option) => option === `$${val}`);
        },
      ),
    label: yup.string().required('Label is required').max(50, 'Label must be less than 50 characters'),
    type: yup.string().required('Variable type is required'),
  });

const CreateEditVariable = ({
  allVariables,
  variable,
  onClose,
  onSave,
  resolvedVariablesValues,
  defaultRegion,
}) => {
  const {
    workspace: { workspace },
  } = useSelector((s) => s);
  const formRef = useRef(null);
  const [preview, setPreview] = useState([]);

  const onSubmit = async (values) => {
    await onSave('variables-settings', {
      payload: values,
      postSave: () => onClose(),
    });
  };

  useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm();
    }
  }, []);

  return (
    <Formik
      innerRef={formRef}
      validateOnChange
      initialValues={
        variable || {
          label: '',
          name: '',
          type: '',
          description: '',
          config: {
            source: 'cloudwatch',
          },
        }
      }
      validationSchema={validationSchema(
        allVariables
          .map((variable) => variable.value)
          .filter((variableName) => {
            return variableName !== `$${variable?.name}`;
          }),
      )}
    >
      {({ values, setFieldValue, isValid, errors }) => {
        return (
          <Form>
            <Grid container direction="column" columnSpacing={2} sx={{ mb: 1 }}>
              <Grid item my={0.5}>
                <Field
                  fullWidth
                  margin="none"
                  name="name"
                  label="Name"
                  placeholder="Variable Name"
                  component={TextField}
                />
              </Grid>
              <Grid item my={0.5}>
                <Field
                  fullWidth
                  margin="none"
                  name="label"
                  label="Label"
                  placeholder="Variable Label"
                  component={TextField}
                />
              </Grid>
              <Grid item my={0.5}>
                <Field
                  fullWidth
                  margin="none"
                  name="description"
                  label="Description"
                  placeholder="Variable Description"
                  inputProps={{
                    multiline: true,
                    maxRows: 4,
                    minRows: 3,
                  }}
                  component={TextField}
                />
              </Grid>
              <Grid item my={0.5}>
                <Field
                  transformValue
                  margin="none"
                  name={'type'}
                  label="Variable type"
                  component={Select}
                  options={variableTypes}
                />
              </Grid>
            </Grid>
            {values.type === 'query' && (
              <QueryVariable
                workspaceId={workspace.id}
                allVariables={allVariables}
                variable={values}
                name="config"
                setFieldValue={setFieldValue}
                setPreview={setPreview}
                resolvedVariablesValues={resolvedVariablesValues}
                defaultRegion={defaultRegion}
              />
            )}
            {values.type === 'constant' && (
              <ConstantVariable
                variable={values}
                name="config"
                setFieldValue={setFieldValue}
                setPreview={setPreview}
              />
            )}
            {values.type && (
              <Stack mt={1} spacing={0.5}>
                <Stack direction="row" justifyContent={'space-between'}>
                  <Typography variant="text">Preview of values</Typography>
                  {values.type === 'query' &&
                    [
                      OTEL_KEY,
                      OTEL_VALUES,
                      'dimension_keys',
                      'dimension_values',
                    ].includes(values.config.queryType) && (
                      <Stack direction="row">
                        <Switch
                          size="small"
                          margin="none"
                          label="Multi Values"
                          name={'is_multi'}
                          checked={values.is_multi}
                          onChange={(e) => {
                            setFieldValue('is_multi', e.target.checked);
                            setFieldValue('value', null);
                          }}
                        />
                        <Switch
                          size="small"
                          margin="none"
                          label="Show All option"
                          name={'show_all_option'}
                          checked={values.show_all_option}
                          onChange={(e) => {
                            setFieldValue('show_all_option', e.target.checked);
                          }}
                        />
                      </Stack>
                    )}
                </Stack>
                {preview.length > 0 && (
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems={'flex-start'}
                    maxHeight={140}
                    sx={{ overflowY: 'auto' }}
                  >
                    {preview.map((option) => (
                      <Chip
                        size="small"
                        label={option.label}
                        sx={{ mr: 1, mt: 1 }}
                      />
                    ))}
                  </Stack>
                )}
              </Stack>
            )}
            <Grid container justifyContent={'end'} gap={1} mt={2}>
              <Box>
                <Button color="primary" variant="text" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  onClick={() => onSubmit(values)}
                  color="primary"
                  loading={false}
                  disabled={!isValid}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateEditVariable;
