import { useCallback } from 'react';
import uPlot from 'uplot';

export const useTZDate = (tz) =>
  useCallback(
    (timestamp) => {
      return uPlot.tzDate(
        new Date(timestamp),
        tz === 'local'
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : tz,
      );
    },
    [tz],
  );
