import { gql } from '@apollo/client';
import { dashboardFields } from './fragments';

export const LIST_DASHBOARDS = gql`
  query getDashboards($workspaceId: String!, $isFolder: Boolean) {
    rows: dashboards(
      where: { workspace_id: { _eq: $workspaceId }, is_folder: { _eq: $isFolder } }
      order_by: [{ stars_aggregate: { count: desc_nulls_last } }, { name: asc }]
    ) {
      id
      name
      description
      is_folder
      tags
      workspace_id
      folder {
        id
        name
      }
      dashboards(
        order_by: [
          { stars_aggregate: { count: desc_nulls_last } }
          { name: asc }
        ]
      ) {
        id
        name
        tags
      }
    }
  }
`;

export const GET_DASHBOARD_BY_ID = gql`
  query getDashboardById($id: String!) {
    dashboard: dashboards_by_pk(id: $id) {
      ...Dashboard
    }
  }
  ${dashboardFields}
`;

export const DELETE_DASHBOARD = gql`
  mutation deleteDashboard($id: String!) {
    delete_dashboards_by_pk(id: $id) {
      ...Dashboard
    }
  }
  ${dashboardFields}
`;

export const UPDATE_DASHBOARD = gql`
  mutation updateDashboard($id: String!, $dashboard: dashboards_set_input!) {
    update_dashboards_by_pk(pk_columns: { id: $id }, _set: $dashboard) {
      ...Dashboard
    }
  }
  ${dashboardFields}
`;

export const CREATE_DASHBOARD = gql`
  mutation createDashboard($dashboard: dashboards_insert_input!) {
    row: insert_dashboards_one(object: $dashboard) {
      ...Dashboard
    }
  }
  ${dashboardFields}
`;

export const STAR_DASHBOARD = gql`
  mutation starDashboard($payload: dashboard_stars_insert_input!) {
    row: insert_dashboard_stars_one(object: $payload) {
      dashboard_id
    }
  }
`;

export const UNSTAR_DASHBOARD = gql`
  mutation unstarDashboard($dashboardId: String!) {
    delete_dashboard_stars(where: { dashboard_id: { _eq: $dashboardId } }) {
      returning {
        dashboard_id
      }
    }
  }
`;

export const GET_SERVICE_DASHBOARDS = gql`
  query getServiceDashboards($query: dashboards_bool_exp!) {
    rows: dashboards(where: $query, limit: 5, order_by: { updated_at: desc }) {
      id
      name
      description
    }
    aggregate: dashboards_aggregate(where: $query) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_DASHBOARDS = gql`
  query listDashboards(
    $query: dashboards_bool_exp!
    $limit: Int
    $offset: Int
  ) {
    rows: dashboards(
      where: $query
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc }
    ) {
      id
      name
    }
  }
`;