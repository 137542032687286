import React, { useEffect, useMemo, useState } from 'react';
import { Switch, Route, Redirect, Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import loadable from '@loadable/component';
import { IntercomProvider } from 'react-use-intercom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AuthWrapper from 'modules/auth/AuthWrapper';
import { useSelector, useDispatch } from 'store';
import Spin from 'components/Spin';
import AccountState from 'components/AccountState';
import Button from 'components/Button';
import Layout from './components/Layout';
import Dashboard from './modules/dashboard';
import Onboarding from './modules/onboarding';
import { setActiveWorkspace, WORKSPACE_KEY, ORG_KEY } from 'store/actions';
import { GET_OWN_INVITES } from 'graphql/invites/queries';
import PendingInvites from './components/PendingInvites';
import GenericHeader from './components/Header/GenericHeader';
import { NotFound } from 'modules/misc';
import { analyticsGroup } from 'utils/segment';
import { env } from 'utils/env';

const INTERCOM_APP_ID = env.REACT_APP_INTERCOM_APP_ID;
//
// const Inventory = loadable(() =>
//   import(/* webpackPrefetch: true */ './modules/inventory/Home'),
// );

const Logs = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/logs'),
);

const Alarms = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/alarms'),
);
const IssueTracker = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/issue-tracker'),
);
const Settings = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/settings'),
);
const Dashboards = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/dashboards'),
);

const Explore = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/explore'),
);

const Lambda = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/lambda'),
);

const AddAccount = loadable(() =>
  import('./modules/settings/containers/organization/AddAccount'),
);

const TraceModule = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/traces'),
);

const VisualizationModule = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/visualization'),
);

const IncidentModule = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/im'),
);

const ServiceMapModule = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/service-map'),
);

const ServiceModule = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/service'),
);

const RumModule = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/rum'),
);

const ReportsModule = loadable(() =>
  import(/* webpackPrefetch: true */ './modules/reports'),
);

const getWorkspaceFromUrl = (workspaceId, orgId, store) => {
  if (!store || !store.user) {
    return { activeOrg: null, workspace: null };
  }

  const orgFromLocalStore = JSON.parse(localStorage.getItem(ORG_KEY));

  if (orgId && orgFromLocalStore?.id === orgId) {
    return {
      activeOrg: localStorage.getItem(ORG_KEY),
      workspace: localStorage.getItem(WORKSPACE_KEY),
    };
  }

  const aclFromUrl = store.user.acl.find(
    (o) => o.org_id === orgId || o.workspace_id === workspaceId,
  );

  if (aclFromUrl) {
    return {
      activeOrg: JSON.stringify({
        id: aclFromUrl.organization.id,
        name: aclFromUrl.organization.name,
        role: aclFromUrl.org_role,
      }),
      workspace: JSON.stringify({
        id: aclFromUrl.workspace?.id,
        name: aclFromUrl.workspace?.name,
        role: aclFromUrl.ws_role,
      }),
    };
  }

  return { activeOrg: null, workspace: null };
};

const RoutesWithLayout = () => {
  const history = useHistory();
  const store = useSelector((s) => s);
  const dispatch = useDispatch();

  const [noAccountAccess, setNoAccountAccess] = useState(false);
  const invitesResp = useQuery(GET_OWN_INVITES, {
    variables: { email: store.user.email },
  });

  const currentWrk = store.workspace?.workspace?.id;
  const currentOrg = store.workspace?.org?.id;

  const { wrkIdFromUrl, orgIdFromUrl } = (() => {
    const params = history.location.pathname.split('/').filter(Boolean);
    const isRedirecting = params.length === 1;
    if (isRedirecting) {
      return {};
    }

    if (params[0] === 'settings') {
      if (['profile', 'invitations'].includes(params[1])) {
        return {};
      }

      if (
        ['workspaces', 'users', 'subscription', 'usage'].includes(params[2])
      ) {
        return { orgIdFromUrl: params[1] };
      }

      return { orgIdFromUrl: params[1], wrkIdFromUrl: params[2] };
    }

    return { wrkIdFromUrl: params[0] };
  })();

  // sync UI and url
  useEffect(() => {
    if (wrkIdFromUrl && currentWrk && wrkIdFromUrl !== currentWrk) {
      history.replace({
        pathname: history.location.pathname.replace(wrkIdFromUrl, currentWrk),
        search: history.location.search,
      });
    }

    if (orgIdFromUrl && currentOrg && orgIdFromUrl !== currentOrg) {
      history.replace({
        pathname: history.location.pathname.replace(orgIdFromUrl, currentOrg),
        search: history.location.search,
      });
    }
  }, [wrkIdFromUrl, currentWrk, orgIdFromUrl, currentOrg]);

  useEffect(() => {
    const { workspace: workspaceFromPath, activeOrg: orgFromUrl } =
      getWorkspaceFromUrl(wrkIdFromUrl, orgIdFromUrl, store);

    let activeOrg = orgFromUrl || localStorage.getItem(ORG_KEY);
    let activeWorkspace =
      workspaceFromPath || localStorage.getItem(WORKSPACE_KEY);

    if (invitesResp.loading) return;

    if (activeOrg && activeWorkspace) {
      // trying to restore session from store.
      activeOrg = JSON.parse(activeOrg);
      activeWorkspace = JSON.parse(activeWorkspace);
      if (activeOrg && activeWorkspace) {
        const acl = store.user.acl.find(
          (o) =>
            o.org_id === activeOrg.id && o.workspace_id === activeWorkspace.id,
        );
        if (acl) {
          const { workspace, workspace_id, ws_role } = acl;
          if (ws_role) {
            activeWorkspace = {
              id: workspace_id,
              name: workspace.name,
              role: ws_role,
            };
          } else {
            activeWorkspace = null;
            activeOrg = null;
          }
        } else {
          // probably user removed from org. Should prompt to signup for own account
          activeOrg = null;
          activeWorkspace = null;
          localStorage.removeItem(ORG_KEY);
          localStorage.removeItem(WORKSPACE_KEY);
        }
      }
    }

    setDefaultActiveOrg(activeOrg, activeWorkspace);
  }, [invitesResp.loading]);

  useEffect(() => {
    if (store.workspace?.org) {
      const company = {
        companyId: store.workspace.org.id,
        name: store.workspace.org.name,
        createdAt: store.workspace.org.created_at,
      };
      analyticsGroup(store.workspace.org.id, company);
    }
  }, [store.workspace]);

  function setDefaultActiveOrg(currentOrg, currentWorkspace) {
    // select first org and account
    let activeOrg = currentOrg;
    let activeSpace = currentWorkspace;

    if (!activeOrg || !activeSpace) {
      const acl = store.user.acl.find((o) => o.ws_role);
      if (acl) {
        activeOrg = {
          id: acl.organization.id,
          name: acl.organization.name,
          role: acl.org_role,
        };
        const { workspace, ws_role } = acl;
        activeSpace = {
          id: workspace.id,
          name: workspace.name,
          role: ws_role,
        };
      } else if (store.user.acl[0]) {
        if (store.user.acl[0].org_role === 'owner') {
          const org = store.user.acl[0].organization;
          dispatch(
            setActiveWorkspace({
              id: org.id,
              name: org.name,
              role: 'owner',
              created_at: org.created_at,
              plan: org.product_key,
            }),
          );
          // TODO: setup guide
          // history.push(`/settings/${store.user.acl[0].org_id}/accounts`);
        } else {
          setNoAccountAccess(true);
        }
      }
    }
    if (activeOrg && activeSpace) {
      dispatch(setActiveWorkspace(activeOrg, activeSpace));
    } else if (!activeOrg && !store.user.acl[0].org_id) {
      if (!invitesResp.loading && !invitesResp.data?.invites.length) {
        history.push('/setup');
      }
    }
  }

  const pendingInvites = useMemo(() => {
    if (invitesResp.data?.invites.length) {
      return (
        <Box>
          <Typography paragraph variant="subtitle1">
            You have pending invite. Please accept to access your account.
          </Typography>
          <PendingInvites invites={invitesResp.data.invites} />
        </Box>
      );
    }
    return null;
  }, [invitesResp.data]);

  if (noAccountAccess) {
    return (
      <>
        <GenericHeader />
        <Box
          margin="auto"
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth={460}
          height="calc(100vh - 90px)"
        >
          <Paper sx={{ p: 3 }}>
            {pendingInvites ? (
              pendingInvites
            ) : (
              <>
                <Typography paragraph variant="subtitle1">
                  You do not have access to any account. Please contact your
                  organization owner.
                </Typography>
                <Button component={Link} to="/setup">
                  Create a new Organization
                </Button>
              </>
            )}
          </Paper>
        </Box>
      </>
    );
  }

  if (!store.workspace) {
    return (
      <Box p={3} textAlign="center">
        <Spin spinning tip="Please wait..." />
      </Box>
    );
  }

  if (!store.workspace?.workspace) {
    return (
      <Layout>
        <Switch>
          <Route path="/settings/:orgId/accounts" component={AddAccount} />
        </Switch>
      </Layout>
    );
  }

  return (
    <Layout>
      <AccountState>
        <Switch>
          <Redirect from="/login" to="/" />
          <Route exact path="/" component={Dashboard} />
          <Route path="/settings" component={Settings} />
          {/*<Route*/}
          {/*  path="/:workspaceId/inventory/:accountId?/:type?"*/}
          {/*  component={Inventory}*/}
          {/*/>*/}
          <Route path="/:workspaceId/lambda/:accountId" component={Lambda} />
          <Route path="/:workspaceId/logs" component={Logs} />
          <Route path="/:workspaceId/traces" component={TraceModule} />
          <Route
            path="/:workspaceId/visualization"
            component={VisualizationModule}
          />
          <Route path="/:workspaceId/explore" component={Explore} />
          <Route path="/:workspaceId/alarms" component={Alarms} />
          <Route path="/:workspaceId/issues" component={IssueTracker} />
          <Route path="/:workspaceId/dashboards" component={Dashboards} />
          <Route path="/:workspaceId/lambda" component={Lambda} />
          <Route path="/:workspaceId/im" component={IncidentModule} />
          <Route
            path="/:workspaceId/service-map"
            component={ServiceMapModule}
          />
          <Route
            path="/:workspaceId/service/:serviceName"
            component={ServiceModule}
          />
          <Route path="/:workspaceId/rum" component={RumModule} />
          <Route path="/:workspaceId/reports" component={ReportsModule} />
          {/*<Redirect*/}
          {/*  from="/inventory"*/}
          {/*  to={`/${store.workspace.workspace.id}/inventory`}*/}
          {/*/>*/}
          <Redirect from="/logs" to={`/${store.workspace.workspace.id}/logs`} />
          <Redirect
            from="/alarms"
            to={`/${store.workspace.workspace.id}/alarms`}
          />
          <Redirect
            from="/issues"
            to={`/${store.workspace.workspace.id}/issues`}
          />
          <Redirect
            from="/explore"
            to={`/${store.workspace.workspace.id}/explore`}
          />
          <Redirect
            from="/dashboards"
            to={`/${store.workspace.workspace.id}/dashboards`}
          />
          <Redirect
            from="/lambda"
            to={`/${store.workspace.workspace.id}/lambda`}
          />
          <Redirect
            from="/traces"
            to={`/${store.workspace.workspace.id}/traces`}
          />
          <Redirect
            from="/visualization"
            to={`/${store.workspace.workspace.id}/visualization`}
          />
          <Redirect from="/im" to={`/${store.workspace.workspace.id}/im`} />
          <Redirect
            from="/service-map"
            to={`/${store.workspace.workspace.id}/service-map`}
          />
          <Redirect
            from="/service"
            to={`/${store.workspace.workspace.id}/service-map`}
          />
          <Redirect from="/rum" to={`/${store.workspace.workspace.id}/rum`} />
          <Redirect
            from="/reports"
            to={`/${store.workspace.workspace.id}/reports`}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </AccountState>
    </Layout>
  );
};

const Routes = () => {
  const hideIntercom = () => {
    window.Intercom('update', {
      hideDefaultLauncher: true,
    });
  };

  return (
    <IntercomProvider
      shouldInitialize={!!INTERCOM_APP_ID}
      autoBoot={false}
      appId={INTERCOM_APP_ID}
      onHide={hideIntercom}
    >
      <AuthWrapper>
        <Switch>
          <Redirect from="/login" to="/" />
          <Route path="/setup" component={Onboarding} />
          <Route>
            <RoutesWithLayout />
          </Route>
        </Switch>
      </AuthWrapper>
    </IntercomProvider>
  );
};

export default Routes;
