import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { useStyles } from './styles';
import useMeasure from 'react-use-measure';
import { useWindowSize } from '@react-hook/window-size';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Label from '../Legend/Label';

function isCursorOutsideCanvas({ left, top }, canvas) {
  if (!canvas) {
    return true;
  }
  if (left === undefined || top === undefined) {
    return false;
  }
  return left < 0 || left > canvas.width || top < 0 || top > canvas.height;
}

const Tooltip = ({
  addHooks,
  xTooltipFormatter,
  yValFormatter,
  yRightValFormatter,
  multiSeriesTooltip,
  highlightFocusedSeries,
  tooltipMaxWidth = 420,
  xIsTime,
  bbox,
  tooltipLocked, 
  setTooltipLocked,
}) => {
  const [placement, setPlacement] = useState({
    x: 0,
    y: 0,
    bbox,
  });
  const classes = useStyles({ ...placement, maxWidth: tooltipMaxWidth });
  const [ref, bounds] = useMeasure({ scroll: true });
  const [width, height] = useWindowSize();
  const [data, setData] = useState([]);
  const [toolTipData, setTooltipData] = useState([]);
  const [series, setSeries] = useState([]);
  const [focusedIdx, setFocusedIdx] = useState();
  const [focusedSeriesIdx, setFocusedSeriesIdx] = useState();
  const [yVal, setYval] = useState('');
  const [cursor, setCursor] = useState({
    left: 0,
    top: 0,
  });
  const tooltipRef = useRef(null);
  const [lockedState, setLockedState] = useState(null);   // Add state to store locked data

  const OFFSET_X = 10;
  const OFFSET_Y = 10;

  useEffect(() => {
    if (tooltipLocked) return; // If tooltip is locked, don't update placement
    const wouldOverflowRight =
      cursor.left + (bbox?.left || 0) + bounds.width + OFFSET_X + 10 > width;
    const wouldOverflowBottom =
      cursor.top + (bbox?.top || 0) + bounds.height + OFFSET_Y + 10 > height;

    setPlacement({
      x: wouldOverflowRight
        ? cursor.left - (bounds.width + OFFSET_X)
        : cursor.left + OFFSET_X,
      y: wouldOverflowBottom
        ? cursor.top - (bounds.height + OFFSET_Y)
        : cursor.top + OFFSET_Y,
      bbox,
    });
  }, [cursor.left, cursor.top, bbox?.left, bbox?.top]);

  useEffect(() => {
    if (
      data &&
      focusedSeriesIdx &&
      typeof data[focusedSeriesIdx][focusedIdx] === 'number'
    ) {
      setYval(data[focusedSeriesIdx][focusedIdx]);
    }
  }, [focusedIdx, focusedSeriesIdx]);

  useEffect(() => {
    addHooks([
      {
        type: 'init',
        func: (_, opts, data) => {
          // If tooltip is not locked, update data, tooltip data, and series
          if (!tooltipLocked) {
            setData(data);
            setTooltipData(opts.tooltipData);
            setSeries(opts.series);
          }
        },
      },
      {
        type: 'setCursor',
        func: (u) => {
          // If cursor is outside canvas and tooltip is not locked, update cursor
          if (!isCursorOutsideCanvas(u.cursor, u.bbox) && !tooltipLocked) {
            setCursor({
              left: u.cursor.left,
              top: u.cursor.top,
            });
          }
        },
      },
      {
        type: 'setLegend',
        func: (u) => {
          // If legend index is not null and tooltip is not locked, update focused index
          if (u.legend.idx !== null && !tooltipLocked) {
            setFocusedIdx(u.legend.idx);
          }
        },
      },
      {
        type: 'setSeries',
        func: (_, seriesIdx) => {
          // If tooltip is not locked, update focused series index
          if (!tooltipLocked) {
            setFocusedSeriesIdx(seriesIdx);
          }
        },
      },
    ]);
  }, [tooltipLocked]);

  useEffect(() => {
    if (tooltipLocked && !lockedState) {
      // Store the current state when locking
      setLockedState({
        data,
        toolTipData,
        focusedIdx,
        focusedSeriesIdx,
        series,
      });
    } else if (!tooltipLocked) {
      setLockedState(null);
    }
  }, [tooltipLocked]);

  // If tooltip is locked, use locked data, otherwise use current data
  const displayData = tooltipLocked && lockedState ? lockedState.data : data;
  const displayTooltipData =
    tooltipLocked && lockedState ? lockedState.toolTipData : toolTipData;
  const displayFocusedIdx =
    tooltipLocked && lockedState ? lockedState.focusedIdx : focusedIdx;
  const displayFocusedSeriesIdx =
    tooltipLocked && lockedState
      ? lockedState.focusedSeriesIdx
      : focusedSeriesIdx;
  const displaySeries =
    tooltipLocked && lockedState ? lockedState.series : series;

  useEffect(() => {
    // Handler for both mousedown and scroll events
    // If user clicks or scrolls outside tooltip while it's locked, unlock it
    const handleOutsideEvent = (event) => {
      if (
        tooltipLocked &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target)
      ) {
        setTooltipLocked(false);
      }
    };

    // Listen for clicks or scrolls anywhere in the document
    document.addEventListener('mousedown', handleOutsideEvent);
    document.addEventListener('scroll', handleOutsideEvent, true);

    // Clean up event listeners
    return () => {
      document.removeEventListener('mousedown', handleOutsideEvent);
      document.removeEventListener('scroll', handleOutsideEvent, true);
    };
  }, [tooltipLocked, setTooltipLocked]);

  return (
    <Box className={classes.tooltipContainer}>
      {displayFocusedSeriesIdx && displaySeries && (
        <Box
          ref={(el) => {
            ref(el);
            tooltipRef.current = el;
          }}
          className={classes.tooltip}
        >
          <Typography gutterBottom variant="body2" fontWeight={700}>
            {xIsTime
              ? xTooltipFormatter(new Date(displayData[0][displayFocusedIdx]))
              : xTooltipFormatter(displayData[0][displayFocusedIdx])}
          </Typography>
          <Stack direction="column">
            {multiSeriesTooltip ? (
              displaySeries.map((_, index) => {
                const yVal = (displayTooltipData || displayData)[index][
                  displayFocusedIdx
                ];
                if (yVal === null || yVal === undefined) {
                  return null;
                }
                const formatter =
                  displaySeries[index].scale === 'right'
                    ? yRightValFormatter
                    : yValFormatter;
                return (
                  index !== 0 && (
                    <Label
                      key={index}
                      text={`${displaySeries[index].name}: ${formatter(yVal)}`}
                      color={
                        displaySeries[index].stroke ||
                        displaySeries[index].color
                      }
                      highlight={
                        !tooltipLocked &&
                        highlightFocusedSeries &&
                        index === displayFocusedSeriesIdx
                      }
                    />
                  )
                );
              })
            ) : (
              <Label
                key={0}
                text={`${displaySeries[displayFocusedSeriesIdx]?.name}: ${
                  displaySeries[displayFocusedSeriesIdx]?.scale === 'right'
                    ? yRightValFormatter(
                        (displayTooltipData || displayData)[displayFocusedSeriesIdx][displayFocusedIdx]
                    )
                    : yValFormatter(
                        (displayTooltipData || displayData)[displayFocusedSeriesIdx][displayFocusedIdx]
                    )
                }`}
                color={
                  displaySeries[displayFocusedSeriesIdx]?.stroke ||
                  displaySeries[displayFocusedSeriesIdx]?.color
                }
              />
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default Tooltip;
